import React from 'react';
import styled from 'styled-components';
import { utils } from '@makeship/core';
import StyledLink from '@makeship/core/lib/components/Link';
import { Caption, P2, Strong } from '../../../Typography';

import { getProductCare, getProductWarning } from '../ProductAccordion';
import { getProductFromContext, getStretchGoalsStateFromContext } from '../../../../context/product';
import { getProductIDFromShopifyGID, getProductType, hasProductTag, parseMetafields } from '../../../../utils/product';
import { ProductTag, ProductType } from '../../../../types/common';
import { AccordionList } from '../../../Accordion';
import { getPDPTrackingLocation, handleSupportLinkTracking } from '../../../../utils/analytics';
import { isHolidayHideawayProduct } from '../../../../utils/bfcm';
import MakeGoodDisclaimer from '../../../MakeGoodDisclaimer';

const SpecsWrapper = styled.div``;

const SingleSpecWrapper = styled.div`
  margin-bottom: 24px;
`;

const FaintCaption = styled(Caption)`
  color: ${({ theme }) => utils.hexToRGBA(theme.colors.neutral7, theme.alpha.dark)};
`;

const SpecsList = styled.ul`
  list-style-type: disc;
`;

const SpecsListItem = styled.li`
  list-style-type: disc;
  margin-left: 30px;
`;

const ProductSpecsText = styled(P2)``;

const Disclaimer = styled(P2)`
  font-weight: 700;
`;

const AccordionBodyHeading = styled(Caption)`
  color: ${({ theme }) => utils.hexToRGBA(theme.colors.neutral7, theme.alpha.dark)};
`;

const DisclaimerContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

const SpecsToggle: React.FC = () => {
  const product = getProductFromContext();
  const { shouldEnableStretchGoals } = getStretchGoalsStateFromContext();

  const metafields = parseMetafields(product.metafields);
  const productType = getProductType(product.productType);
  const isGTReward = hasProductTag(product.tags, ProductTag.RewardGT);
  const isMakeGoodProduct =
    getProductIDFromShopifyGID(product.id) === '7519085691019' ||
    getProductIDFromShopifyGID(product.id) === '8365980188828';

  const renderMaterialsSection = (materials: (string | JSX.Element)[]) => (
    <>
      <AccordionBodyHeading>Materials</AccordionBodyHeading>
      <AccordionList>
        {materials.map((material) => (
          <ProductSpecsText>{material}</ProductSpecsText>
        ))}
      </AccordionList>
    </>
  );

  const renderCareInstructions = (instructions: (string | JSX.Element)[]) => (
    <>
      <AccordionBodyHeading>Care Instructions</AccordionBodyHeading>
      <AccordionList>
        {instructions.map((instruction) => (
          <ProductSpecsText>{instruction}</ProductSpecsText>
        ))}
      </AccordionList>
    </>
  );

  const renderSpecsList = (specs: { name: string; key: string; unit: string; default: string }[]) => (
    <SpecsList>
      {specs.map((spec) => (
        <SpecsListItem key={spec.name}>
          <P2>
            {spec.name}: {metafields[spec.key as keyof typeof metafields] || spec.default}
            {spec.unit}
          </P2>
        </SpecsListItem>
      ))}
    </SpecsList>
  );

  if (productType === ProductType.Hoodie) {
    return (
      <>
        {renderMaterialsSection([
          '55% cotton, 45% polyester.*',
          '*Exclusive of decorative details.',
          <Strong>Our premium poly-cotton blend offers a warm, low maintenance and more durable Hoodie.</Strong>,
        ])}
        <br />
        {renderCareInstructions([
          'Machine washable. Please follow care instructions on Hoodie label.',
          <>
            Please contact{' '}
            <StyledLink.Primary
              href="https://support.makeship.com/hc/en-us/requests/new"
              onClick={() => handleSupportLinkTracking('open_support_ticket', getPDPTrackingLocation(product), product)}
            >
              Makeship Customer Support
            </StyledLink.Primary>{' '}
            with any questions or if you need assistance.
          </>,
        ])}
      </>
    );
  }

  if (productType === ProductType.Crewneck) {
    return (
      <>
        {renderMaterialsSection(['100% cotton yarn*', '*Exclusive of decorative details.'])}
        <br />
        {renderCareInstructions([
          'Machine wash cold inside out. Do not bleach. Tumble dry low. Do not iron on print. Keep away from fire. Please follow all care instructions on the garment label.',
        ])}
      </>
    );
  }

  const productCare = getProductCare(metafields)[productType] || {};

  // Make Good 2025
  const renderMakeGoodSpecsList = () => (
    <SpecsList>
      <SpecsListItem>
        <P2>Keychain: 11.5cm (H) x 10.5cm (W)</P2>
      </SpecsListItem>
      <SpecsListItem>
        <P2>Bag: 57cm (H) x 41cm (W)</P2>
      </SpecsListItem>
    </SpecsList>
  );

  const renderMakeGoodMaterials = () => (
    <SpecsList>
      <SpecsListItem>
        <P2>Keychain: PP Cotton, Plush Fabric, Nylex Fabric</P2>
      </SpecsListItem>
      <SpecsListItem>
        <P2>Bag: 100% polyester</P2>
      </SpecsListItem>
    </SpecsList>
  );

  const renderMakeGoodCareInstructions = () => (
    <SpecsList>
      <SpecsListItem>
        <P2>Keychain: Handwash warm or cool only. Do not machine wash or dry clean.</P2>
      </SpecsListItem>
      <SpecsListItem>
        <P2>Bag: Machine wash delicate OR hand wash and hang to dry.</P2>
      </SpecsListItem>
    </SpecsList>
  );

  return (
    <SpecsWrapper>
      {productCare.dimensions && (
        <SingleSpecWrapper>
          <FaintCaption>Dimensions</FaintCaption>
          {isMakeGoodProduct ? renderMakeGoodSpecsList() : renderSpecsList(productCare.dimensions)}
          {productCare.disclaimer && <FaintCaption>{productCare.disclaimer}</FaintCaption>}
        </SingleSpecWrapper>
      )}

      {productCare.material && (
        <SingleSpecWrapper>
          <FaintCaption>Materials</FaintCaption>
          <SpecsList>
            {isMakeGoodProduct
              ? renderMakeGoodMaterials()
              : productCare.material.map((material) => (
                  <SpecsListItem>
                    <P2>{material}</P2>
                  </SpecsListItem>
                ))}
          </SpecsList>
        </SingleSpecWrapper>
      )}

      {productCare.careInstructions && (
        <SingleSpecWrapper>
          <FaintCaption>Care Instructions</FaintCaption>
          <P2>{isMakeGoodProduct ? renderMakeGoodCareInstructions() : productCare.careInstructions}</P2>
        </SingleSpecWrapper>
      )}

      {productType && (
        <SingleSpecWrapper>
          <P2>{getProductWarning(productType)}</P2>
        </SingleSpecWrapper>
      )}

      {isGTReward && (
        <DisclaimerContainer>
          <Disclaimer>
            One of each physical Gorilla Tag Birthday Bash reward per unit purchased. Birthday Accessory only fits
            Plushie and Doughboi.
          </Disclaimer>
          <Disclaimer>
            Only redeem 1 code per Gorilla Tag account. Each code unlocks both Banana Party Hat and Tagged Monke
            Plushie. Redeeming multiple codes will result in lost codes. Share extras with friends!
          </Disclaimer>
        </DisclaimerContainer>
      )}
      {shouldEnableStretchGoals && (
        <Disclaimer>One {metafields.creator} reward/free gift per unit purchased.</Disclaimer>
      )}
      {/* GITD 2024 */}
      {isHolidayHideawayProduct(product.id) && (
        <SingleSpecWrapper>
          <P2>
            <strong>Santa Fiya GITD Plushie not included in purchase of Holiday Hideaway GITD Plushie.</strong>
          </P2>
        </SingleSpecWrapper>
      )}
      {/* Make Good 2025 */}
      {isMakeGoodProduct && (
        <SingleSpecWrapper>
          <MakeGoodDisclaimer />
        </SingleSpecWrapper>
      )}
    </SpecsWrapper>
  );
};

export default SpecsToggle;
