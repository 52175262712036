/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { NextPage, GetServerSideProps, GetServerSidePropsContext } from 'next';
import { utils } from '@makeship/core';
import posthog, { Properties } from 'posthog-js';
import Container from '../../components/Container';
import Head from '../../components/Head';
import NotificationBar from '../../components/NotificationBar';
import { Caption } from '../../components/Typography';

import { getProductByHandle, getProductByID } from '../../api/product';
import { getCollectionByHandle } from '../../api/collections';
import {
  getProductSalesQuantityByExternalProductId,
  getProductSalesQuantityForGT,
  getTargetURLFromPath,
} from '../../api/storefront/product';

import {
  getStage,
  parseMetafields,
  getLiveProductsRemoveCurrentOne,
  getProductIDFromShopifyGID,
  hasProductTag,
  getProductType,
  isProductSoldOut,
  getEvergreenInventoryState,
  getFirstThreeUniqueCreators,
  findCollectionByMetafield,
} from '../../utils/product';

import config from '../../../config.json';
import { getFormattedAmount } from '../../utils/accounting';
import {
  analyticsTrackProductPageLoad,
  calculateMinutesLeft,
  isUserLoggedIn,
  sendViewItemEvent,
} from '../../utils/analytics';
import {
  getIsCompleteTheCollectionEligible,
  parseCreatorCollectionMetafields,
  fetchLatestProductDropDetails,
} from '../../utils/collections';

import { ProductStage, ProductTag, ProductType, productMOQ } from '../../types/common';
import { useStore } from '../../store';
import ProductProvider, { ProductContextType } from '../../context/product';
import ProductPageCountdown from '../../components/ProductPage/ProductPageCountdown';
import PreviewWarning from '../../components/Preview/PreviewWarning';
import ProductPageCarousel from '../../components/ProductPage/ProductPageCarousel';
import ProductInfo from '../../components/pages/products/ProductInfo';
import ProductHeader from '../../components/pages/products/ProductHeader';
import { removeFirstProductImage } from '../../utils/hoodies';
import { getPropsForPageLoad } from '../../utils/pageLoad';

import { ExperimentVariant } from '../../context/flagging';
import { experimentKeys, retrievePosthogFlagBagServerSide } from '../../utils/flagging';

const { pagination, collections } = config;
declare const window: any;

const ProductPageWrapper = styled(Container)<{ needsPadding: boolean }>`
  display: flex;
  flex-direction: column;
  padding: ${({ needsPadding }) => (needsPadding ? '48px 64px' : '8px 64px')};
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.largeTablet}px) {
    padding: ${({ needsPadding }) => (needsPadding ? '48px 8px' : '8px')};
  }
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.mobile}px) {
    padding: ${({ needsPadding }) => (needsPadding ? '16px 8px' : '8px')};
  }
`;

const ProductDetailsWrapper = styled.div`
  width: 100%;
  display: flex;
  margin-bottom: 8px;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.largeTablet}px) {
    flex-direction: column;
    margin-bottom: 72px;
    padding: 0 8px;
  }
`;

const ProductInfoWrapper = styled.div<{ isVinyl: boolean }>`
  flex: 1 1;
  display: flex;
  flex-direction: column;
  padding-left: 40px;
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.largeDesktop}px) {
    padding-left: 0px;
  }
`;

const ProductHeaderWrapper = styled.div`
  flex: 1 1;
  display: none;
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.largeTablet}px) {
    display: block;
  }
`;

const NotificationBarContentWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const NotificationBarText = styled(Caption)`
  margin-left: 8px;
  text-transform: uppercase;
  color: ${({ theme }) => theme.colors.neutral1};
`;

const NotificationBarContent = (
  <NotificationBarContentWrapper>
    🕓
    <NotificationBarText>Last chance</NotificationBarText>
  </NotificationBarContentWrapper>
);

export type ProductPageProps = {
  handle: string;
  product: Shopify.Product;
  topCampaigns: Shopify.ProductEdge[];
  productsBySameCreator: Shopify.ProductEdge[];
  productsInSameMakeshiftCollection: Shopify.ProductEdge[];
  makeshiftCollectionTitle: string | null;
  has360View: boolean;
  currentProduct360ImagesEdges: Shopify.ImageEdge[] | null;
  isDraft?: boolean;
  giftProduct?: Shopify.Product | null;
  pinAddOnProduct?: Shopify.Product | null;
  productSalesQuantity: ProductSalesQuantity | null;
  creatorCollection: Shopify.CollectionEdge | null;
  latestProductDropCollection: Shopify.Collection | null;
  productDropCollectionMetafields: ProductDropCollectionMetafields | null;
  shouldEnableStretchGoals: boolean;
  goalsAchieved: boolean;
  productDropSalesQuantity: number;
};

const ProductPage: NextPage<ProductPageProps> = ({
  handle,
  product,
  topCampaigns,
  productsBySameCreator,
  productsInSameMakeshiftCollection,
  makeshiftCollectionTitle,
  has360View,
  currentProduct360ImagesEdges,
  isDraft = false,
  giftProduct,
  pinAddOnProduct,
  productSalesQuantity,
  creatorCollection,
  latestProductDropCollection,
  productDropCollectionMetafields,
  shouldEnableStretchGoals,
  goalsAchieved,
  productDropSalesQuantity,
}: ProductPageProps) => {
  const { state } = useStore();
  const metafields = parseMetafields(product.metafields);
  const stage = getStage(product.tags);
  const productType = getProductType(product.productType);
  const [productImages, setProductImages] = useState<Shopify.ImageEdge[]>(
    removeFirstProductImage(product.images.edges, productType, stage),
  );
  const [product360Images, setProduct360Images] = useState<Shopify.ImageEdge[] | null>(currentProduct360ImagesEdges);
  const [selectedVariant, setSelectedVariant] = useState<Shopify.ProductVariant>();
  const [currentDate, setCurrentDate] = useState<string>();
  const [currentHas360View, setCurrentHas360View] = useState<boolean>(has360View);
  const moq = Number(metafields.moq) || productMOQ[product.productType] || config.defaultMOQ;
  const shopifyProductInventory = Math.abs(product.totalInventory ?? 0);
  const currentProductSalesQuantity = productSalesQuantity ? productSalesQuantity.quantity : null;
  // Use metafield total sold if campaign is past, else calculate the total inventory sold
  const totalInventory =
    stage === ProductStage.Past && metafields.totalsold
      ? Number(metafields.totalsold)
      : currentProductSalesQuantity ?? shopifyProductInventory;
  const isSoldOut = isProductSoldOut(product, shopifyProductInventory);
  const hasProductionDesign = product.variants.edges.length === 2;
  const isCompleteTheCollectionEligible = getIsCompleteTheCollectionEligible(
    productsBySameCreator,
    productsInSameMakeshiftCollection,
    hasProductionDesign,
    stage,
    isSoldOut,
    product,
    pinAddOnProduct,
  );
  const isEvergreen = hasProductTag(product.tags, ProductTag.Evergreen);

  useEffect(() => {
    analyticsTrackProductPageLoad(product, product.variants.edges[0]?.node, state.user);
    sendViewItemEvent(product, product.variants.edges[0]?.node);
    setProductImages(removeFirstProductImage(product.images.edges, productType, stage));
    setCurrentHas360View(has360View);
    setProduct360Images(currentProduct360ImagesEdges);
  }, [product, currentProduct360ImagesEdges, has360View, state.user, productType, stage]);

  useEffect(() => {
    if ((window as any).drift && (window as any).drift.api) {
      (window as any).drift.api.widget.hide();
    }
    return () => {
      if ((window as any).drift && (window as any).drift.api) {
        (window as any).drift.api.widget.show();
      }
    };
  }, []);

  useEffect(() => {
    if (!posthog.__loaded) return;

    const posthogProperties: Properties = {
      creator: metafields.creator,
      productName: product.title,
      productID: getProductIDFromShopifyGID(product.id),
      hasManyVariants: product.variants.edges.length > 1,
      launchDate: metafields.launch,
      campaignStatus: stage,
      campaignID: metafields.campaignID,
      unitsSold: totalInventory,
      productType: product.productType,
      isCompleteTheCollectionEligible,
      evergreenInventoryState: getEvergreenInventoryState(product),
      is_logged_in: isUserLoggedIn(state.user),
      tags: product.tags,
      isDraft,
      minutes_left: calculateMinutesLeft(metafields, stage),
      productsBySameCreator: productsBySameCreator.map(({ node }) => getProductIDFromShopifyGID(node.id)),
      productsInSameMakeshiftCollection: productsInSameMakeshiftCollection.map(({ node }) =>
        getProductIDFromShopifyGID(node.id),
      ),
    };

    if (window && window.location) {
      posthogProperties.current_url = window.location.href;
    }

    posthog.capture('$pageview', posthogProperties);
    if (typeof window !== 'undefined' && window.rdt) {
      window.rdt('track', 'ViewContent', {
        ...posthogProperties,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [posthog.__loaded, product]);

  // Used to trigger Klaviyo Viewed Page segment
  useEffect(() => {
    const _learnq = (window as any)._learnq || [];
    const page = `${process.env.NEXT_PUBLIC_DOMAIN || ''}/products/${handle}`;
    _learnq.push(['track', 'Viewed Page', { url: page }]);
  }, [handle]);

  useEffect(() => {
    let date: string | undefined;
    if ([ProductStage.Live, ProductStage.ComingSoon, ProductStage.Draft].includes(stage)) {
      date = stage === ProductStage.ComingSoon || stage === ProductStage.Draft ? metafields.launch : metafields.enddate;
    }
    setCurrentDate(date);
  }, [metafields.launch, metafields.enddate, stage]);

  const productProviderValue: ProductContextType = {
    product,
    productDetails: {
      moq,
      setMOQ: (moq) => moq,
      totalInventory,
      setTotalInventory: (totalInventory) => totalInventory,
    },
    stretchGoalsState: {
      shouldEnableStretchGoals,
      goalsAchieved,
    },
  };

  return (
    <ProductProvider value={productProviderValue}>
      {currentDate && <ProductPageCountdown currentDate={currentDate} totalInventory={totalInventory} />}
      <ProductPageWrapper needsPadding={!currentDate}>
        <Head
          title={product.title}
          url={`/products/${handle}`}
          description={product.description}
          type="product"
          currency="USD"
          image={productImages[0]?.node.transformedSrc || ''}
          price={getFormattedAmount(product.variants.edges[0].node.price?.amount)}
        />
        {isDraft && <PreviewWarning />}
        <ProductDetailsWrapper>
          {!isEvergreen && (
            <ProductHeaderWrapper data-testid="mobile-product-header">
              <ProductHeader selectedVariant={selectedVariant} creatorCollection={creatorCollection} />
            </ProductHeaderWrapper>
          )}
          <ProductPageCarousel
            productImages={productImages}
            product360Images={product360Images}
            currentHas360View={currentHas360View}
          />
          <ProductInfoWrapper isVinyl={getProductType(product.productType) === ProductType.VinylFigure}>
            <ProductInfo
              selectedVariant={selectedVariant}
              setSelectedVariant={(variant) => setSelectedVariant(variant)}
              setProductImages={setProductImages}
              topCampaigns={topCampaigns}
              makeshiftCollectionTitle={makeshiftCollectionTitle}
              productsBySameCreator={productsBySameCreator}
              giftProduct={giftProduct || null}
              pinAddOnProduct={pinAddOnProduct || null}
              productsInSameMakeshiftCollection={productsInSameMakeshiftCollection}
              creatorCollection={creatorCollection}
              latestProductDropCollection={latestProductDropCollection}
              productDropCollectionMetafields={productDropCollectionMetafields}
              shouldEnableStretchGoals={shouldEnableStretchGoals}
              currentProductDropSalesQuantity={productDropSalesQuantity}
            />
          </ProductInfoWrapper>
        </ProductDetailsWrapper>
        {currentDate && (
          <NotificationBar
            start={stage === ProductStage.Live && utils.getDayDiffFrom(currentDate) === 0}
            content={NotificationBarContent}
            top={196}
          />
        )}
      </ProductPageWrapper>
    </ProductProvider>
  );
};

export const getServerSideProps: GetServerSideProps<ProductPageProps> = async (ctx: GetServerSidePropsContext) => {
  const { params } = ctx;
  const handle = params ? (params.handle as string) : '';
  const propsForPageLoad = await getPropsForPageLoad(ctx);

  const product = await getProductByHandle(handle);

  if (!product) {
    const redirectResponse = await getTargetURLFromPath(`/products/${handle}`)
      .then((response) => response.data)
      .catch(() => null);

    if (redirectResponse?.targetUrl) {
      return {
        redirect: {
          destination: redirectResponse.targetUrl,
          permanent: true,
        },
      };
    }

    return {
      notFound: true,
    };
  }

  if (hasProductTag(product.tags, ProductStage.Draft)) {
    return {
      notFound: true,
    };
  }

  const currentStage = getStage(product.tags);
  const petitionStages = [ProductStage.Petition, ProductStage.PetitionSuccess, ProductStage.PetitionFailed];

  if (petitionStages.includes(currentStage)) {
    return {
      redirect: {
        destination: `/petitions/${handle}`,
        permanent: false,
      },
    };
  }

  const creatorCollection = findCollectionByMetafield(product, 'isCreatorCollection', 'true');
  const makeshiftCollection = findCollectionByMetafield(product, 'isMakeshiftCollection', 'true');

  const hasFundedBarOverride = hasProductTag(product.tags, ProductTag.FundedBarOverride);
  const giftProductID = parseMetafields(product.metafields)?.gift;
  const pinAddOnProductID = parseMetafields(product.metafields)?.pinAddOn;
  const isPastOrFailed = [ProductStage.Past, ProductStage.Failed].includes(currentStage);

  const [
    currentCampaignsData,
    detailedCreatorCollection,
    productsInMakeshiftCollection,
    flagBag,
    giftProduct,
    pinAddOnProduct,
    productSalesQuantity,
  ] = await Promise.all([
    isPastOrFailed
      ? getCollectionByHandle(collections.liveCampaigns, undefined, 1, pagination.topCampaignsRedirect).then(
          (data) => data?.products.edges as Shopify.ProductEdge[],
        )
      : Promise.resolve([]),
    creatorCollection
      ? getCollectionByHandle(creatorCollection.node.handle, undefined, 1, pagination.completeCollection).then(
          (collection) => collection,
        )
      : Promise.resolve(null),
    makeshiftCollection
      ? getCollectionByHandle(makeshiftCollection.node.handle, undefined, 1, pagination.carousel).then(
          (collection) => collection?.products?.edges || [],
        )
      : Promise.resolve([]),
    retrievePosthogFlagBagServerSide(ctx),
    giftProductID ? getProductByID(giftProductID) : Promise.resolve(null),
    pinAddOnProductID ? getProductByID(pinAddOnProductID) : Promise.resolve(null),
    hasFundedBarOverride
      ? Promise.resolve({ quantity: Math.abs(product.totalInventory ?? 0) })
      : getProductSalesQuantityByExternalProductId(getProductIDFromShopifyGID(product.id))
          .then((response) => response.data)
          .catch(() => null),
  ]);

  const productsInCreatorCollection = detailedCreatorCollection?.products?.edges || [];

  const currentCampaigns = currentCampaignsData.filter(
    (campaign) => !hasProductTag(campaign.node.tags, ProductTag.Hidden),
  );

  const topCampaigns = getFirstThreeUniqueCreators(currentCampaigns);

  const productsBySameCreator = getLiveProductsRemoveCurrentOne(productsInCreatorCollection, product);

  const productsInSameMakeshiftCollection = getLiveProductsRemoveCurrentOne(productsInMakeshiftCollection, product);

  if (currentStage === ProductStage.ComingSoon) {
    product.images.edges = product.images.edges.slice(0, 1);
    product.variants.edges.forEach((edge) => {
      const { image } = edge.node;
      if (image) {
        image.transformedSrc = null;
      }
    });
  }

  const has360View = product.images.edges.some((edge) => edge.node.transformedSrc.includes('_360_'));

  let currentProduct360ImagesEdges: Shopify.ImageEdge[] | null = null;

  if (has360View) {
    const [productImageEdges, product360ImagesEdges] = product.images.edges.reduce(
      (result, edge) => {
        result[edge.node.transformedSrc.includes('_360_') ? 1 : 0].push(edge);
        return result;
      },
      [[] as Shopify.ImageEdge[], [] as Shopify.ImageEdge[]],
    );
    product.images.edges = productImageEdges;
    currentProduct360ImagesEdges = product360ImagesEdges;
  }

  let productDropCollectionMetafields: ProductDropCollectionMetafields | null = null;
  let latestProductDropCollection: Shopify.Collection | null = null;
  let shouldEnableStretchGoals = false;
  let goalsAchieved = false;
  let productDropSalesQuantity = 0;

  const isGTReward = hasProductTag(product.tags, ProductTag.RewardGT);
  if (isGTReward) {
    const gtSalesQuantity = await getProductSalesQuantityForGT()
      .then((response) => response.data)
      .catch(() => null);

    if (gtSalesQuantity) {
      productDropSalesQuantity = gtSalesQuantity.quantity;
    }
  }

  if (detailedCreatorCollection) {
    const { metafields } = detailedCreatorCollection;

    const creatorCollectionMetafields = parseCreatorCollectionMetafields(metafields);

    const { productDropCollections } = creatorCollectionMetafields;

    if (productDropCollections) {
      ({
        productDropCollectionMetafields,
        latestProductDropCollection,
        shouldEnableStretchGoals,
        goalsAchieved,
        productDropSalesQuantity,
      } = await fetchLatestProductDropDetails(productDropCollections, pagination.completeCollection, product.id));
    }
  }

  return {
    props: {
      handle,
      product,
      topCampaigns,
      productsBySameCreator,
      productsInSameMakeshiftCollection,
      makeshiftCollectionTitle: makeshiftCollection?.node.title || null,
      has360View,
      currentProduct360ImagesEdges,
      giftProduct,
      pinAddOnProduct,
      productSalesQuantity,
      creatorCollection: creatorCollection || null,
      productDropCollectionMetafields,
      latestProductDropCollection,
      shouldEnableStretchGoals,
      goalsAchieved,
      productDropSalesQuantity,
      ...propsForPageLoad,
    },
  };
};

export default ProductPage;
